<template>
  <banded-section
    title="Import Relations"
    :collapsable="false"
  >
    <template slot="tooltip">
      <div class="m-b-m">
        This section is visible to the Hypefactors team only.
      </div>

      <div class="m-b-m">
        Here you can upload a CSV file with a predefined structure containing one or more relations.
        These will be added to the selected Brands
      </div>
    </template>

    <div class="Media-profile-import columns">
      <div class="column is-6 is-offset-3">
        <form enctype="multipart/form-data" @submit.prevent>
          <form-field
            :validator="$v.form.organisation"
            label="Organisation"
          >
            <organisations-picker
              v-model="form.organisation"
              popper-class="OrganisationsModal"
              placeholder="Choose Organisation"
            />
          </form-field>

          <form-field
            :validator="$v.form.brands"
            label="Select Brands"
          >
            <basic-select
              v-model="form.brands"
              :values="brandsToPickFrom"
              label-prop="name"
              value-prop="id"
              key-prop="id"
              multiple
              clearable
            />
          </form-field>

          <form-field
            :validator="$v.form.csv"
            label="CSV file"
          >
            <el-upload
              ref="uploader"
              :on-change="file => form.csv = file || null"
              :on-remove="handleFileRemoval"
              :on-exceed="handleFileExceed"
              v-bind="fileUpload.options"
              class="m-b-m"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
              <div class="is-size-7 has-text-grey m-t-m">
                {{ $t('forms.accepted_file_types') }} {{ fileUpload.options.accept }}
              </div>
            </el-upload>
          </form-field>

          <div class="m-b-m">
            Click
            <a
              :href="$asset('/files/examples/import/relations_example.csv')"
              target="_blank"
              class="has-text-primary"
            >here</a> to download a sample file to use as a template for importing relations.
          </div>

          <div class="m-b-m">
            Click
            <a
              :href="$asset('/files/examples/import/valid_categories.txt')"
              target="_blank"
              class="has-text-primary"
            >here</a> to download the list of the valid category identifiers that can be used during imports.
          </div>

          <hr class="m-b-m">

          <div class="m-b-m">
            <el-checkbox v-model="form.shouldOverride">
              Override existing results if they already exist?
            </el-checkbox>
          </div>

          <v-button
            v-if="!isValidated"
            :loading="form.isLoading"
            type="button"
            class="is-primary is-pulled-right"
            @click.prevent="validate"
          >
            Validate
          </v-button>

          <v-button
            v-else
            :loading="form.isLoading"
            :disabled="validationResults.length > 0"
            type="button"
            class="is-black is-pulled-right"
            @click.prevent="process"
          >
            Submit for Processing
          </v-button>
        </form>

        <table v-if="validationResults.length" class="m-t-xl table table-bordered is-fullwidth">
          <thead>
            <th>Row</th>
            <th>Errors</th>
          </thead>
          <tbody>
            <tr
              v-for="(result, index) in validationResults"
              :key="index"
              :class="result.type"
            >
              <td class="is-narrow">
                {{ result.row }}
              </td>
              <td v-html="flattenErrors(result.errors)" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </banded-section>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import OrganisationsPicker from '@/components/forms/OrganisationsPicker'
import { flattenErrorMessage } from '@hypefactors/shared/js/utils/handleErrors'

function createForm () {
  const form = new Form({
    organisation: { value: null, rules: { required } },
    brands: { value: null, rules: { required } },
    csv: { value: null, rules: { required } },
    shouldOverride: { value: false }
  })

  form.setPayloadTransformer(payload => {
    let formData = new FormData()

    formData.append('organisation', payload.organisation.id)
    payload.brands.forEach((brand) => {
      formData.append('brands[]', brand)
    })
    formData.append('csv', payload.csv.raw)
    formData.append('should_override', payload.shouldOverride ? '1' : '0')

    return formData
  })

  return form
}

export default {
  components: { OrganisationsPicker },

  data () {
    return {
      form: createForm(),
      validationResults: [],
      isValidated: false
    }
  },

  computed: {
    brandsToPickFrom () {
      return this.$safeGet(this.form.organisation, 'brands.data', [])
    },
    fileUpload () {
      return {
        options: {
          limit: 1,
          multiple: false,
          autoUpload: false,
          accept: 'text/csv',
          action: '',
          drag: true,
          headers: {
            Authorization: `Bearer ${this.authToken}`
          }
        }
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    handleFileExceed () {
      this.$alert('You can only upload one file at a time. Please remove the old one.')
    },
    handleFileRemoval () {
      this.form.csv = null
      this.validationResults = []
      this.isValidated = false
    },
    async validate () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      try {
        const result = await this.form.submit('post', '/staff/connect/relations/import/validate', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.validationResults = result.data
        this.isValidated = true
      } catch (e) {
        this.$displayRequestError(e)
      }
    },
    async process () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      try {
        await this.form.submit('post', '/staff/connect/relations/import/process', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.$alert("Importing is now being processed, once finished you'll receive an email.")

        this.form.reset()
        this.$v.form.$reset()
        this.$refs.uploader.clearFiles()
        this.validationResults = []
        this.isValidated = false
      } catch (err) {
        this.$displayRequestError(err)
      }
    },
    flattenErrors (errors) {
      return flattenErrorMessage(
        Object.values(errors).map(error => error[0])
      )
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.Media-profile-import {
  .el-upload, .el-upload-dragger {
    width: 100%;
  }

  .result {
    padding: $padding-small $padding;

    &.warn {
      border-left: 4px solid $hf__color-warning;
      background: rgba($hf__color-warning, 0.1);
    }

    &.error {
      border-left: 4px solid $hf__color-danger;
      background: rgba($hf__color-danger, 0.1);
    }

    &.info {
      border-left: 4px solid $hf__color-info;
      background: rgba($hf__color-info, 0.1);
    }
  }
}
</style>
